//font-colors
@font__primary: #17181D;
@font__second-primary: #FFFFFF;
@font__secondary: #808080;
@font__colorful: #CC1111;


@background__button: #D63029;
@background__card: #F9F9F9;
@background__input: #F4F4F4;

//viewports
@tablet-width: 768px;
@desktop-width: 1256px;
@mobile-width: 320px;

//font-sizes
.fonts(@font-size, @line-height, @font-weight) {
  font-size: @font-size;
  line-height: @line-height;
  font-weight: @font-weight;

  @media (max-width: @desktop-width) {
    font-size: @font-size * 0.9;
    line-height: @line-height;
    font-weight: @font-weight;
  }

  @media (max-width: @tablet-width) {
    font-size: @font-size * 0.75;
    line-height: @line-height;
    font-weight: @font-weight;
  }

  @media (max-width: @mobile-width) {
    font-size: @font-size * 0.6;
    line-height: @line-height;
    font-weight: @font-weight;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}

img {
  display: block;
}

a {
  text-decoration: none;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.8;
  }
}

ul,
ol {
  padding: 0;
  margin: 0;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: inherit;
}

dl,
p,
dd,
dt {
  margin: 0;
  padding: 0;
}

.button {
  cursor: pointer;
  background-color: @background__button;
  color: @font__second-primary;
  padding: 28px;
  .fonts(16px, 1, 700);
  border-radius: 68px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  text-align: center;
  display: inline-block;
  transition: opacity 0.2s;
  border: none;

  &:hover {
    opacity: 0.8;
  }
}

.eye-catching {
  font-weight: 600;
}

.title {
  .fonts(48px, 1.2, 300);
  position: relative;

  &::before {
    position: absolute;
    content: "";
    width: 50px;
    height: 50px;
    background-image: url("../img/icons/stack.svg#title-decoration");
    top: 0;
    left: -56px;

    @media (max-width: @tablet-width) {
      display: none;
    }
  }
}

.border-clip {
  clip-path: ellipse(94% 56% at 50% 43%);

  &--top {
    clip-path: ellipse(94% 56% at 51% 56%);
  }

  @media (max-width: @desktop-width) {
    clip-path: initial;
  }
}

