//Базовые свойства
@import "base.less";

//Блоки компонентов
.page {
  height: 100%;

  &__body {
    min-height: 100%;
    margin: 0 auto;
    font-family: "Raleway", "Tahoma", sans-serif;
    font-size: 13px;
    line-height: 1.3;
    font-weight: 300;
    color: @font__primary;
  }
}

.container {
  max-width: 1720px;
  margin: 0 auto;
  padding: 0 80px;

  @media (max-width: @desktop-width) {
    padding: 0 60px;
  }

  @media (max-width: @tablet-width) {
    padding: 0 40px;
  }

  @media (max-width: 580px) {
    padding: 0 20px;
  }
}

.form {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 12px 24px;

  &__label {
    @media (max-width: 500px) {
      width: 100%;
    }
  }

  &__input {
    padding: 22px 28px;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1) inset;
    border-radius: 20px;
    border: 1px @font__second-primary solid;
    background-color: @background__input;
    color: @font__primary;
    .fonts(16px, 1.6, 400);
    width: 340px;

    @media (max-width: @desktop-width) {
      padding: 16px 20px;
    }

    @media (max-width: 500px) {
      width: 100%;
    }

    &::placeholder {
      .fonts(16px, 1.6, 400);
      color: rgba(23, 24, 29, 0.5);
    }
  }

  &__label--accept {
    flex-basis: 100%;
    cursor: pointer;

    .form__accept-input:checked + .form__accept-label::before {
      background-color: @font__colorful;
      background-image: url("../img/icons/stack.svg#check");
      background-size: 8px;
      background-repeat: no-repeat;
      background-position: center;
    }

    .form__accept-label {
      .fonts(15px, 1.4, 300);
      padding-left: 28px;
      position: relative;
      display: block;

      &::before {
        position: absolute;
        content: "";
        width: 16px;
        height: 16px;
        border: 1px @font__colorful solid;
        border-radius: 4px;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .form__accept-link {
      color: @font__colorful;
      font-weight: 500;
    }
  }

  &__button {
    padding: 28px;
    flex-grow: 1;
    max-width: 340px;

    @media (max-width: @desktop-width) {
      padding: 20px;
    }
  }
}

.when {
  position: relative;
  padding-left: 16px;

  &::before {
    position: absolute;
    content: "";
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #12c868;
    top: 4px;
    left: 0;
  }
}

.second-color {
  color: @font__second-primary;
}

.hero {
  background-image: image-set(url("../img/hero-background.webp") type("image/webp") 1x,
  url("../img/hero-background@2x.webp") type("image/webp") 2x,
  url("../img/hero-background.jpg") 1x,
  url("../img/hero-background@2x.jpg") 2x);
  background-color: #484343;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
  min-height: 970px;

  @media (max-width: @desktop-width) {
    &::before {
      position: absolute;
      background: linear-gradient(to bottom, rgba(110, 110, 110, 0) 40%, #2b2725 65%);
      content: "";
      width: 100%;
      height: 100%;
      z-index: 1;

      @media (max-width: 1100px) {
        background: linear-gradient(180deg, rgba(110, 110, 110, 0) 40%, #2b2725 61%);
      }

      @media (max-width: 980px) {
        background: linear-gradient(180deg, rgba(110, 110, 110, 0) 40%, #2b2725 59%);
      }

      @media (max-width: 880px) {
        background: linear-gradient(180deg, rgba(110, 110, 110, 0) 40%, #2b2725 57%);
      }

      @media (max-width: 810px) {
        background: linear-gradient(180deg, rgba(110, 110, 110, 0) 40%, #2b2725 55%);
      }

      @media (max-width: 780px) {
        background: linear-gradient(180deg, rgba(110, 110, 110, 0) 40%, #2b2725 53%);
      }

      @media (max-width: @tablet-width) {
        background: linear-gradient(180deg, rgba(110, 110, 110, 0) 40%, #2b2725 59%);
      }

      @media (max-width: 680px) {
        background: linear-gradient(180deg, rgba(110, 110, 110, 0) 40%, #2b2725 55%);
      }

      @media (max-width: 600px) {
        background: linear-gradient(180deg, rgba(110, 110, 110, 0) 40%, #2b2725 51%);
      }
    }
  }

  &__wrapper {
    display: flex;
    position: relative;
    padding-top: 220px;
    padding-bottom: 212px;

    @media (max-width: @desktop-width) {
      padding-bottom: 100px;
      flex-direction: column;
    }

    @media (max-width: @tablet-width) {
      padding-bottom: 60px;
    }

    @media (max-width: @mobile-width) {
      padding-bottom: 40px;
    }
  }

  &__description-wrapper {
    max-width: 770px;
    position: relative;
    z-index: 1;
  }

  &__title {
    .fonts(64px, 1.15, 300);
    margin-bottom: 40px;

    .price {
      color: #cecece;
      padding: 16px 24px;
      .fonts(20px, 1.15, 600);
      background-color: rgba(255, 255, 255, 0.16);
      border-radius: 20px;
      vertical-align: middle;
      text-wrap: nowrap;
    }
  }

  &__description {
    .fonts(22px, 1.5, 500);
    position: relative;
    max-width: 491px;
    padding-left: 64px;
    margin-bottom: 40px;
    color: @font__secondary;

    &::before {
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      width: 48px;
      height: 26px;
      background-image: url("../img/icons/stack.svg#dots");
    }
  }

  &__owner-wrapper {
    position: absolute;
    bottom: -2%;
    right: 0;

    @media (max-width: @desktop-width) {
      position: relative;
    }

    @media (max-width: 480px) {
      margin-bottom: 60px;
    }
  }

  &__owner-image {
    position: absolute;
    bottom: 0;
    right: -140px;
    z-index: 0;

    @media (max-width: @desktop-width) {
      position: initial;
      width: 100%;
      height: auto;
    }
  }

  &__owner-description-wrapper {
    padding: 20px;
    background-color: rgba(31, 31, 36, 0.52);
    border-radius: 20px;
    position: absolute;
    z-index: 1;
    backdrop-filter: blur(5px);
    max-width: 300px;
    bottom: 177px;
    right: 10%;
    min-width: 300px;

    @media (max-width: @desktop-width) {
      top: 10%;
      left: 10%;
      bottom: initial;
      right: initial;
    }

    @media (max-width: @tablet-width) {
      min-width: 150px;
    }

    @media (max-width: 680px) {
      left: 0;
    }

    @media (max-width: 580px) {
      top: 0;
    }

    @media (max-width: 480px) {
      top: initial;
      left: initial;
      right: 20px;
      bottom: -45px;
      backdrop-filter: blur(20px);
    }

    @media (max-width: 380px) {
      top: initial;
      left: initial;
      right: 15px;
      bottom: -45px;
    }
  }

  &__owner-name {
    .fonts(24px, 1.3, 500);
    margin-bottom: 16px;
  }

  &__owner-description {
    .fonts(16px, 1.6, 400);
    opacity: 0.8;
  }
}

.conditions {
  padding-top: 120px;
  padding-bottom: 120px;

  @media (max-width: @desktop-width) {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  @media (max-width: @tablet-width) {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  @media (max-width: @mobile-width) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  &__title {
    margin-bottom: 60px;
  }

  &__list {
    display: flex;
    gap: 32px;
    flex-wrap: wrap;

    @media (max-width: 1600px) {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(600px, 1fr));
    }

    @media (max-width: @tablet-width - 1px) {
      gap: 20px;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    }
  }

  &__item {
    padding: 40px;
    background-color: @background__card;
    min-height: 300px;
    border-radius: 24px;
    background-image: image-set(url("../img/conditions__item--closet.webp") type("image/webp") 1x, url("../img/conditions__item--closet@2x.webp") type("image/webp") 2x, url("../img/conditions__item--closet.png") 1x, url("../img/conditions__item--closet@2x.png") 2x);
    background-repeat: no-repeat;
    background-position: right bottom;
    min-width: calc(52% - 32px);

    @media (max-width: 1600px) {
      min-width: initial;
      min-height: initial;
    }

    @media (max-width: @tablet-width) {
      padding: 20px;
    }

    &--document {
      background-image: image-set(url("../img/conditions__item--document.webp") type("image/webp") 1x, url("../img/conditions__item--document@2x.webp") type("image/webp") 2x, url("../img/conditions__item--document.png") 1x, url("../img/conditions__item--document@2x.png") 2x);
    }

    &--details {
      background-image: image-set(url("../img/conditions__item--details.webp") type("image/webp") 1x, url("../img/conditions__item--details@2x.webp") type("image/webp") 2x, url("../img/conditions__item--details.png") 1x, url("../img/conditions__item--details@2x.png") 2x);
    }

    &--computer {
      background-image: image-set(url("../img/conditions__item--computer.webp") type("image/webp") 1x, url("../img/conditions__item--computer@2x.webp") type("image/webp") 2x, url("../img/conditions__item--computer.png") 1x, url("../img/conditions__item--computer@2x.png") 2x);
    }

    @media (max-width: @tablet-width - 1px) {
      background-image: initial;
    }

    &:nth-child(3n+1) {
      min-width: calc(48% - 32px);

      @media (max-width: 1600px) {
        min-width: initial;
      }
    }
  }

  &__item-title {
    .fonts(26px, 1.2, 300);
    margin-bottom: 20px;
    max-width: 340px;
  }

  &__description {
    .fonts(16px, 1.6, 300);
    max-width: 352px;

    @media (max-width: 1600px) {
      max-width: 50%;
    }

    @media (max-width: @tablet-width - 1px) {
      max-width: initial;
    }
  }
}

.advantages {
  padding-top: 120px;
  margin-bottom: -350px;
  position: relative;
  z-index: 1;

  @media (max-width: 1800px) {
    margin-bottom: -250px;
  }

  @media (max-width: @desktop-width) {
    margin-bottom: 0;
    padding: 120px 0;
  }

  @media (max-width: @tablet-width) {
    padding-top: 60px;
    padding-bottom: 60px;
    margin-bottom: 0;
  }

  @media (max-width: @mobile-width) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  &__title {
    margin-bottom: 40px;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: 40px;
    margin-bottom: 60px;

    @media (max-width: @desktop-width) {
      grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    }
  }

  &__item {
    .fonts(22px, 1.4, 300);
    padding-top: 32px;
    position: relative;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 7px;
      left: 0;
      background-color: @font__colorful;
      width: 14px;
      height: 2px;
    }

    &::after {
      transform: rotate(90deg);
    }
  }

  &__video {
    border-radius: 60px;

    @media (max-width: @desktop-width) {
      height: 700px;
    }

    @media (max-width: @tablet-width) {
      height: 500px;
    }

    @media (max-width: @mobile-width) {
      height: 300px;
    }
  }
}

.our-projects {
  margin-bottom: -152px;
  background-image: image-set(url("../img/our-projects-background.webp") type("image/webp") 1x, url("../img/our-projects-background@2x.webp") type("image/webp") 2x, url("../img/our-projects-background.jpg") 1x, url("../img/our-projects-background@2x.jpg") 2x);
  background-color: #484343;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 120px 0 272px;

  @media (max-width: @tablet-width) {
    padding-top: 60px;
    padding-bottom: 60px;
    margin-bottom: 60px;
  }

  @media (max-width: @mobile-width) {
    padding-top: 40px;
    padding-bottom: 40px;
    margin-bottom: 40px;
  }

  &__wrapper {
    display: flex;
    gap: 20px;
    margin-bottom: 60px;
    align-items: center;
    flex-wrap: wrap;
  }

  &__title {
    max-width: 1200px;
  }

  &__hint {
    .fonts(16px, 1.4, 300);
    opacity: 0.6;
    padding-left: 52px;
    width: 320px;
    position: relative;

    &::before {
      position: absolute;
      content: "";
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 32px;
      height: 32px;
      background-image: url("../img/icons/stack.svg#hand");
    }
  }
}

.slider {
  overflow: hidden;

  .container {
    position: relative;

    &--slider {
      padding: 0 40px;

      @media (max-width: @desktop-width) {
        padding: 0 40px;
      }

      @media (max-width: @tablet-width) {
        padding: 0 20px;
      }

      @media (max-width: 500px) {
        padding: 0 10px;
      }
    }
  }

  &__button-wrapper {
    max-width: 120px;
    min-width: 120px;
    display: flex;
    gap: 20px;

    &--main {
      position: absolute;
      right: 80px;
      z-index: 1;

      @media (max-width: @tablet-width) {
        right: 20px;
      }

      @media (max-width: 400px) {
        top: 20px;
      }
    }

    @media (max-width: 600px) {
      max-width: 90px;
      min-width: 90px;
      gap: 10px;
    }

    @media (max-width: 400px) {
      max-width: 65px;
      min-width: 65px;
      gap: 5px;
    }
  }

  &__button {
    width: 50px;
    height: 50px;
    background-color: rgba(255, 255, 255, 0.1);
    border: none;
    border-radius: 50%;
    cursor: pointer;
    background-image: url("../img/icons/stack.svg#arrow");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16px;

    &--prev {
      transform: rotate(180deg);
    }

    @media (max-width: 600px) {
      width: 40px;
      height: 40px;
      background-size: 13px;
    }

    @media (max-width: 400px) {
      width: 30px;
      height: 30px;
      background-size: 10px;
    }
  }

  &__list {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    gap: 40px;
    transition: transform 0.5s ease-in-out;
  }

  &__item {
    display: flex;
    flex-direction: column;
    min-width: 100%;

    &--active {
      .slider__description-wrapper {
        opacity: 1;
      }

      .slider__mini::before {
        opacity: 0;
        z-index: -1;
      }
    }
  }

  &__name-wrapper {
    display: grid;
    row-gap: 12px;

    @media (max-width: @desktop-width) {
      padding-right: 120px;
    }

    @media (max-width: 500px) {
      padding-right: 80px;
    }

    @media (max-width: @mobile-width) {
      padding-right: 65px;
    }
  }

  &__description-wrapper {
    padding: 0 40px;
    display: flex;
    gap: 60px;
    width: 100%;
    margin-bottom: 40px;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    padding-right: 160px;

    @media (max-width: 1400px) {
      flex-direction: column;
      gap: 40px;
      padding: 0 20px;
    }

    @media (max-width: @mobile-width) {
      gap: 20px;
    }
  }

  &__description-list {
    display: grid;
    gap: 12px 40px;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: repeat(4, 1fr);

    @media (max-width: 600px) {
      grid-template-columns: 1fr 1fr;
      grid-auto-flow: row dense;
      gap: 0 20px;

      .slider__description-number:nth-of-type(odd) {
        grid-column: 1 / 2;
        grid-row: initial;
      }

      .slider__description-number:nth-of-type(even) {
        grid-column: 2 / 3;
        grid-row: initial;
      }

      .slider__definition:nth-of-type(even) {
        grid-column: 2 / 3;
      }

      .slider__definition:nth-of-type(odd) {
        grid-column: 1 / 2;
      }
    }
  }

  &__description-number {
    grid-row: 1 / 2;
  }

  &__description-number,
  &__name {
    .fonts(28px, 1.2, 500);
  }

  &__definition,
  &__city {
    .fonts(18px, 1.2, 300);
    color: rgba(255, 255, 255, 0.7);
  }

  &__mini {
    border-radius: 60px;
    position: relative;
    width: 100%;
    display: block;
    max-width: 1640px;
    overflow: hidden;

    @media (max-width: @desktop-width) {
      border-radius: 40px;
    }

    @media (max-width: @tablet-width) {
      border-radius: 20px;
    }

    @media (max-width: 500px) {
      border-radius: 8px;
    }

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(23, 24, 29, 0.7);
      backdrop-filter: blur(30px);
      z-index: 1;
      transition: opacity 0.5s ease-in-out;
      border-radius: 60px;
      overflow: hidden;

      @media (max-width: @desktop-width) {
        border-radius: 40px;
      }

      @media (max-width: @tablet-width) {
        border-radius: 20px;
      }

      @media (max-width: 500px) {
        border-radius: 8px;
      }
    }

    .slider__button-wrapper {
      position: absolute;
      right: 40px;
      bottom: 40px;

      .slider__button {
        background-color: rgba(23, 24, 29, 0.3);
      }

      @media (max-width: @tablet-width) {
        right: 20px;
        bottom: 20px;
      }

      @media (max-width: 400px) {
        right: 10px;
        bottom: 10px;
      }
    }
  }

  &__photos {
    display: flex;
    transition: transform 0.5s ease-in-out;
  }

  &__photo {
    min-width: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
  }
}

.price-form {
  padding: 60px;
  box-shadow: 0 40px 60px -24px rgba(0, 0, 0, 0.08);
  border-radius: 60px;
  display: grid;
  grid-template-columns: 1fr 4fr;
  gap: 40px;
  position: relative;
  z-index: 1;
  background-color: @font__second-primary;

  @media (max-width: @desktop-width) {
    grid-template-columns: initial;
    padding: 52px;
  }

  @media (max-width: @tablet-width) {
    padding: 36px;
  }

  @media (max-width: 500px) {
    padding: 0;
    box-shadow: initial;
  }

  &__description-wrapper {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
  }

  &__title {
    .fonts(40px, 1.2, 600);
    margin-bottom: 32px;
  }

  &__description {
    .fonts(24px, 1.4, 300);
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
  }

  &__item {
    .fonts(16px, 1.6, 400);
    max-width: 340px;
    min-width: 340px;
    padding-left: 68px;
    position: relative;

    @media (max-width: 500px) {
      min-width: initial;
    }

    &::before {
      position: absolute;
      content: "";
      width: 48px;
      height: 48px;
      border: 1px @font__colorful solid;
      border-radius: 50%;
      left: 0;
      top: 0;
      background-image: url("../img/icons/stack.svg#red-decoratioin-tick");
      background-size: 12px;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}

.experience {
  background-color: #484343;
  position: relative;

  @media (max-width: 580px) {
    padding-bottom: 40px;

    &::before {
      position: absolute;
      background: linear-gradient(to bottom, rgba(110, 110, 110, 0) 16%, #2b2725 63%);
      content: "";
      width: 100%;
      height: 100%;

      @media (max-width: 480px) {
        background: linear-gradient(180deg, rgba(110, 110, 110, 0) 11%, #2b2725 57%);
      }

      @media (max-width: 440px) {
        background: linear-gradient(180deg, rgba(110, 110, 110, 0) 10%, #2b2725 52%);
      }

      @media (max-width: 400px) {
        background: linear-gradient(180deg, rgba(110, 110, 110, 0) 10%, #2b2725 50%);
      }

      @media (max-width: 380px) {
        background: linear-gradient(180deg, rgba(110, 110, 110, 0) 11%, #2b2725 47%);
      }
    }
  }

  &__description-wrapper {
    display: flex;
    flex-direction: column;
    max-width: 536px;
    margin-top: auto;
    position: absolute;
    bottom: 380px;

    @media (max-width: 1024px) {
      top: 60px;
    }

    @media (max-width: @tablet-width) {
      top: 40px;
    }

    @media (max-width: 580px) {
      position: initial;
    }
  }

  &__title {
    font-weight: 600;
    margin-bottom: 40px;

    @media (max-width: 440px) {
      margin-bottom: 20px;
    }

    &::before {
      display: none;
    }

    .eye-catching {
      display: block;
      .fonts(74px, 1.3, 300);

      @media (max-width: 1400px) {
        .fonts(60px, 1.3, 300);
      }

      @media (max-width: 580px) {
        .fonts(40px, 1.3, 300);
      }
    }
  }

  &__description {
    .fonts(24px, 1.36, 400);
    padding-left: 74px;
    position: relative;
    max-width: 440px;

    @media (max-width: 1400px) {
      .fonts(20px, 1.3, 300);
    }

    @media (max-width: 1024px) {
      max-width: 360px;
      padding-left: 40px;
    }

    @media (max-width: @tablet-width) {
      max-width: 200px;
    }

    @media (max-width: 580px) {
      max-width: initial;
    }

    &::before {
      position: absolute;
      content: "";
      width: 50px;
      height: 2px;
      background-color: @font__colorful;
      border-radius: 10px;
      left: 0;
      top: 20px;

      @media (max-width: 1024px) {
        width: 28px;
      }
    }
  }
}

.steps {
  padding: 120px 0;

  @media (max-width: @desktop-width) {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  @media (max-width: @tablet-width) {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  @media (max-width: @mobile-width) {
    padding: 40px 0;
  }

  &__inner {
    display: flex;
    justify-content: space-between;

    @media (max-width: @desktop-width) {
      flex-direction: column;
    }
  }

  &__description-wrapper {
    margin-right: 80px;
    margin-bottom: 20px;
    max-width: 512px;

    @media (max-width: @desktop-width) {
      max-width: initial;
      margin-right: 0;
    }
  }

  &__title {
    margin-bottom: 32px;
  }

  &__description {
    .fonts(24px, 1.4, 300);
    margin-bottom: 32px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    row-gap: 80px;
    counter-reset: num;

    @media (max-width: @desktop-width) {
      row-gap: 40px;
    }
  }

  &__item {
    padding-left: 72px;
    position: relative;

    &::before {
      position: absolute;
      content: counter(num);
      counter-increment: num;
      color: @font__colorful;
      .fonts(32px, 1.05, 300);
      top: 0;
      left: 0;
    }

    &:nth-child(-n+9)::before {
      content: "0" counter(num);
    }

    &-title {
      .fonts(22px, 1.2, 600);
      margin-bottom: 12px;
    }

    &-description {
      .fonts(16px, 1.4, 300);

      .eye-catching {
        font-weight: 500;
      }
    }
  }
}

.history {
  background-color: #484343;
  background-image: image-set(url("../img/history-background.webp") type("image/webp") 1x,
  url("../img/history-background@2x.webp") type("image/webp") 2x,
  url("../img/history-background.jpg") 1x,
  url("../img/history-background@2x.jpg") 2x);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;

  @media (max-width: @desktop-width) {
    &::before {
      position: absolute;
      background: linear-gradient(to bottom, rgba(110, 110, 110, 0) 16%, #2b2725 75%);
      content: "";
      width: 100%;
      height: 100%;
      z-index: 1;

      @media (max-width: 980px) {
        background: linear-gradient(180deg, rgba(110, 110, 110, 0) 40%, #2b2725 72%);
      }

      @media (max-width: 880px) {
        background: linear-gradient(180deg, rgba(110, 110, 110, 0) 40%, #2b2725 68%);
      }

      @media (max-width: 580px) {
        background: linear-gradient(180deg, rgba(110, 110, 110, 0) 40%, #2b2725 63%);
      }

      @media (max-width: 480px) {
        background: linear-gradient(180deg, rgba(110, 110, 110, 0) 40%, #2b2725 60%);
      }

      @media (max-width: 400px) {
        background: linear-gradient(180deg, rgba(110, 110, 110, 0) 40%, #2b2725 57%);
      }

      @media (max-width: 350px) {
        background: linear-gradient(180deg, rgba(110, 110, 110, 0) 40%, #2b2725 54%);
      }
    }
  }

  &__title {
    max-width: 50%;
    margin-bottom: 80px;

    @media (max-width: @desktop-width) {
      max-width: initial;
    }

    @media (max-width: 880px) {
      margin-bottom: 40px;
    }
  }

  &__description-wrapper {
    .fonts(22px, 1.6, 400);
    max-width: 566px;
    padding-left: 72px;
    position: relative;

    @media (max-width: @desktop-width) {
      max-width: initial;
      z-index: 1;
    }

    @media (max-width: 480px) {
      padding-left: 48px;
    }

    &::before {
      position: absolute;
      content: "";
      width: 44px;
      height: 44px;
      left: 0;
      top: 0;
      background-image: url("../img/icons/stack.svg#history-quote");

      @media (max-width: 480px) {
        width: 33px;
        height: 33px;
      }
    }
  }

  &__inner {
    padding: 160px 0 260px;
    position: relative;

    @media (max-width: @desktop-width) {
      padding-top: 100px;
      padding-bottom: 100px;
    }

    @media (max-width: @tablet-width) {
      padding-top: 60px;
      padding-bottom: 60px;
    }

    @media (max-width: @mobile-width) {
      padding: 40px 0;
    }
  }

  &__image {
    position: absolute;
    bottom: -20px;
    right: -180px;
    z-index: -1;

    @media (max-width: @desktop-width) {
      position: initial;
    }
  }
}

.our-team {
  padding: 220px 0;
  margin-top: -100px;
  background-color: @background__card;

  @media (max-width: @tablet-width) {
    padding-top: 60px;
    padding-bottom: 60px;
    margin: 0;
  }

  @media (max-width: @mobile-width) {
    padding: 40px 0;
  }

  &__title {
    margin-bottom: 20px;
    max-width: 60%;

    @media (max-width: @desktop-width) {
      max-width: 80%;
    }

    @media (max-width: @tablet-width) {
      max-width: initial;
    }
  }

  &__description {
    margin-bottom: 60px;
    max-width: 50%;
    font-size: 24px;

    @media (max-width: @desktop-width) {
      max-width: 80%;
    }

    @media (max-width: @tablet-width) {
      max-width: initial;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: 40px;

    @media (max-width: @desktop-width) {
      grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    }
  }

  &__item {
    text-align: center;
  }

  &__image {
    background-color: @font__second-primary;
    border-radius: 40px;
    object-fit: contain;
    width: 100%;
    margin-bottom: 20px;
    object-position: center bottom;
  }

  &__name {
    .fonts(20px, 1.3, 600);
    margin-bottom: 8px;
  }

  &__post,
  &__experience {
    .fonts(15px, 1.6, 300);
    color: @font__secondary;
  }

  .eye-catching {
    font-weight: 600;
    color: @font__primary;
  }
}

.contacts {
  background-color: #484343;
  background-image: image-set(url("../img/contacts-background.webp") type("image/webp") 1x,
  url("../img/contacts-background@2x.webp") type("image/webp") 2x,
  url("../img/contacts-background.jpg") 1x,
  url("../img/contacts-background@2x.jpg") 2x);
  background-position: center;
  background-size: cover;
  padding: 120px 0;

  @media (max-width: @tablet-width) {
    padding: 60px 0;
  }

  @media (max-width: @mobile-width) {
    padding: 40px 0;
  }

  &__inner {
    display: grid;
    gap: 60px;
    grid-template-columns: repeat(auto-fill, minmax(460px, 1fr));
    align-items: start;

    @media (max-width: @desktop-width) {
      row-gap: 40px;
      column-gap: 28px;
    }

    @media (max-width: @tablet-width) {
      grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
      gap: 20px;
    }
  }

  &__background {
    padding: 32px;
    border-radius: 40px;
    background-color: rgba(23, 24, 29, 0.4);
    position: relative;
    border: 1px solid rgba(255, 255, 255, 0.16);

    @media (max-width: @tablet-width) {
      padding: 24px;
    }
  }

  &__description-wrapper {
    @media (max-width: 1619px) {
      grid-column: 1 / -1;
    }
  }

  &__title {
    margin-bottom: 40px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__item {
    position: relative;
    padding-left: 32px;
    .fonts(18px, 1.6, 400);

    &::after {
      position: absolute;
      content: "";
      width: 12px;
      height: 12px;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background-image: url("../img/icons/stack.svg#red-decoratioin-tick");
    }
  }

  &__wrapper-title {
    .fonts(16px, 1.2, 700);
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    align-content: flex-start;

    .contacts__background {
      width: 100%;
    }
  }

  &__phone {
    font-style: inherit;
  }

  &__phone-title {
    margin-bottom: 16px;
  }

  &__phone-link {
    .fonts(32px, 1.2, 700);
    display: block;
    color: inherit;
    margin-bottom: 8px;
  }

  &__messengers-title {
    margin-bottom: 16px;
  }

  &__messengers-wrapper {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(auto-fill, minmax(192px, 1fr));
  }

  &__messengers-link {
    padding: 18px 40px;
    padding-left: 72px;
    .fonts(14px,1.2,700);
    color: inherit;
    background-color: #05a0eb;
    border-radius: 100px;
    width: 100%;
    text-align: center;

    @media (max-width: @tablet-width) {
      padding: 16px 16px;
      padding-left: 56px;
      min-width: 140px;
    }

    span {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        width: 21px;
        height: 17px;
        top: 50%;
        transform: translateY(-50%);
        left: -37px;
        background-image: url("../img/icons/stack.svg#telegram");

        @media (max-width: @tablet-width) {
          width: 15px;
          height: 12px;
          left: -32px;
        }
      }
    }

    &--whatsapp {
      background-color: #12c868;

      span::before {
        background-image: url("../img/icons/stack.svg#whatsapp");
        width: 22px;
        height: 22px;
        left: -38px;

        @media (max-width: @tablet-width) {
          width: 16px;
          height: 16px;
          left: -33px;
        }
      }
    }
  }

  &__social {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 40px;
  }

  &__social-links-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 24px;
  }

  &__social-link {
    svg {
      vertical-align: middle;
    }

    &--vkontakte {
      color: #0077ff;
    }

    &--youtube {
      color: #ff0302;
    }
  }

  &__form {
    background-color: rgba(23, 24, 29, 0.49);
    box-shadow: 0 50px 60px -32px rgba(0, 0, 0, 0.4);
    padding: 60px;

    @media (max-width: @tablet-width) {
      padding: 28px;
    }
  }

  &__form-title {
    .fonts(32px, 1.2, 600);
    margin-bottom: 8px;
  }

  &__form-description {
    .fonts(20px, 1.4, 300);
    margin-bottom: 20px;
  }

  .form {
    gap: 16px;

    &__accept-label {
      margin-top: 4px;
    }

    &__label,
    &__input,
    &__button {
      width: 100%;
      max-width: initial;
    }
  }
}

.main-footer {
  padding: 40px 0;
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  .fonts(14px, 1.3, 400);

  @media (max-width: 880px) {
    flex-direction: column;
    gap: 20px;
  }

  &__logo-wrapper {
    display: flex;
    justify-content: space-between;
    max-width: 420px;
    gap: 32px;
    align-items: center;

    @media (max-width: 880px) {
      flex-wrap: wrap;
      justify-content: center;
      gap: 20px;
    }
  }

  &__description {
    font-size: 13px;
    line-height: 1.4;

    @media (max-width: 880px) {
      text-align: center;
    }
  }

  &__company {
    text-align: center;
    min-width: 200px;
  }

  &__document {
    text-align: center;
    min-width: 240px;
    color: inherit;
  }

  .main-footer__social-links-wrapper {
    min-width: 68px;
    flex-wrap: nowrap;

    .contacts__social-link {
      color: @font__primary;
    }
  }

  &__address {
    text-align: center;
    min-width: 168px;
    max-width: 168px;
  }

  &__phone {
    text-align: center;
    min-width: 176px;
  }

  .contacts__phone-link {
    .fonts(20px, 1, 700);
    margin-bottom: 8px;
  }

  .contacts__when {
    .fonts(13px, 1, 400);
    padding: 0;

    &::before {
      display: none;
    }
  }
}

.main-header {
  position: absolute;
  z-index: 1;
  width: 100%;
  padding: 24px 0;

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__logo-wrapper {
    display: flex;
    justify-content: space-between;
    max-width: 448px;
    gap: 28px;
    align-items: center;
  }

  &__logo-image {
    @media (max-width: @tablet-width) {
      width: 100px;
      height: 40px;
    }
  }

  &__description {
    font-size: 18px;
    line-height: 1.4;
    color: #cdcdcd;

    @media (max-width: 1100px) {
      display: none;
    }
  }

  &__contacts-wrapper {
    display: flex;
    gap: 48px;
    margin-left: 40px;
    align-items: center;

    @media (max-width: 1100px) {
      gap: 24px;
      margin-left: initial;
    }

    @media (max-width: 400px) {
      flex-direction: column;
      gap: 12px;
      align-items: flex-end;
    }
  }

  &__messengers-wrapper {
    display: flex;
    align-items: center;
  }

  &__messengers-description {
    .fonts(16px, 1.4, 300);
    text-align: right;
    min-width: 128px;

    @media (max-width: @tablet-width) {
      min-width: 100px;
    }

    @media (max-width: 580px) {
      display: none;
    }
  }

  &__messengers-link {
    padding: 0;
    min-width: 48px;
    min-height: 48px;
    max-width: 48px;
    max-height: 48px;
    border-radius: 50%;
    background-color: #05a0eb;
    background-image: url("../img/icons/stack.svg#telegram");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 21px 17px;
    margin-left: 20px;

    @media (max-width: @tablet-width) {
      min-width: 30px;
      min-height: 30px;
      max-width: 30px;
      max-height: 30px;
      background-size: 15px 11px;
      margin-left: 16px;
    }

    &--whatsapp {
      background-color: #12c868;
      background-image: url("../img/icons/stack.svg#whatsapp");
      background-size: 22px 22px;
      margin-left: 12px;

      @media (max-width: @tablet-width) {
        background-size: 16px 16px;
      }
    }
  }

  &__phone {
    min-width: 208px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: @tablet-width) {
      min-width: initial;
    }
  }

  .contacts__when {
    padding-left: 12px;
    .fonts(14px, 1, 300);
    margin-bottom: 12px;

    &::before {
      width: 4px;
      height: 4px;
      top: 5px;
    }

    @media (max-width: 580px) {
      display: none;
    }
  }

  .contacts__phone-link {
    .fonts(24px, 1, 700);
    margin-bottom: 0;

    @media (max-width: 580px) {
      font-size: 12px;
    }
  }

  &__button {
    .fonts(12px, 1, 700);
    padding: 20px 32px;

    @media (max-width: 1100px) {
      display: none;
    }
  }
}
