*,
*::before,
*::after {
  box-sizing: border-box;
}
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}
img {
  display: block;
}
a {
  text-decoration: none;
  transition: opacity 0.2s;
}
a:hover {
  opacity: 0.8;
}
ul,
ol {
  padding: 0;
  margin: 0;
  list-style: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: inherit;
}
dl,
p,
dd,
dt {
  margin: 0;
  padding: 0;
}
.button {
  cursor: pointer;
  background-color: #D63029;
  color: #FFFFFF;
  padding: 28px;
  font-size: 16px;
  line-height: 1;
  font-weight: 700;
  border-radius: 68px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  text-align: center;
  display: inline-block;
  transition: opacity 0.2s;
  border: none;
}
@media (max-width: 1256px) {
  .button {
    font-size: 14.4px;
    line-height: 1;
    font-weight: 700;
  }
}
@media (max-width: 768px) {
  .button {
    font-size: 12px;
    line-height: 1;
    font-weight: 700;
  }
}
@media (max-width: 320px) {
  .button {
    font-size: 9.6px;
    line-height: 1;
    font-weight: 700;
  }
}
.button:hover {
  opacity: 0.8;
}
.eye-catching {
  font-weight: 600;
}
.title {
  font-size: 48px;
  line-height: 1.2;
  font-weight: 300;
  position: relative;
}
@media (max-width: 1256px) {
  .title {
    font-size: 43.2px;
    line-height: 1.2;
    font-weight: 300;
  }
}
@media (max-width: 768px) {
  .title {
    font-size: 36px;
    line-height: 1.2;
    font-weight: 300;
  }
}
@media (max-width: 320px) {
  .title {
    font-size: 28.8px;
    line-height: 1.2;
    font-weight: 300;
  }
}
.title::before {
  position: absolute;
  content: "";
  width: 50px;
  height: 50px;
  background-image: url("../img/icons/stack.svg#title-decoration");
  top: 0;
  left: -56px;
}
@media (max-width: 768px) {
  .title::before {
    display: none;
  }
}
.border-clip {
  clip-path: ellipse(94% 56% at 50% 43%);
}
.border-clip--top {
  clip-path: ellipse(94% 56% at 51% 56%);
}
@media (max-width: 1256px) {
  .border-clip {
    clip-path: initial;
  }
}
.page {
  height: 100%;
}
.page__body {
  min-height: 100%;
  margin: 0 auto;
  font-family: "Raleway", "Tahoma", sans-serif;
  font-size: 13px;
  line-height: 1.3;
  font-weight: 300;
  color: #17181D;
}
.container {
  max-width: 1720px;
  margin: 0 auto;
  padding: 0 80px;
}
@media (max-width: 1256px) {
  .container {
    padding: 0 60px;
  }
}
@media (max-width: 768px) {
  .container {
    padding: 0 40px;
  }
}
@media (max-width: 580px) {
  .container {
    padding: 0 20px;
  }
}
.form {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 12px 24px;
}
@media (max-width: 500px) {
  .form__label {
    width: 100%;
  }
}
.form__input {
  padding: 22px 28px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1) inset;
  border-radius: 20px;
  border: 1px #FFFFFF solid;
  background-color: #F4F4F4;
  color: #17181D;
  font-size: 16px;
  line-height: 1.6;
  font-weight: 400;
  width: 340px;
}
@media (max-width: 1256px) {
  .form__input {
    font-size: 14.4px;
    line-height: 1.6;
    font-weight: 400;
  }
}
@media (max-width: 768px) {
  .form__input {
    font-size: 12px;
    line-height: 1.6;
    font-weight: 400;
  }
}
@media (max-width: 320px) {
  .form__input {
    font-size: 9.6px;
    line-height: 1.6;
    font-weight: 400;
  }
}
@media (max-width: 1256px) {
  .form__input {
    padding: 16px 20px;
  }
}
@media (max-width: 500px) {
  .form__input {
    width: 100%;
  }
}
.form__input::placeholder {
  font-size: 16px;
  line-height: 1.6;
  font-weight: 400;
  color: rgba(23, 24, 29, 0.5);
}
@media (max-width: 1256px) {
  .form__input::placeholder {
    font-size: 14.4px;
    line-height: 1.6;
    font-weight: 400;
  }
}
@media (max-width: 768px) {
  .form__input::placeholder {
    font-size: 12px;
    line-height: 1.6;
    font-weight: 400;
  }
}
@media (max-width: 320px) {
  .form__input::placeholder {
    font-size: 9.6px;
    line-height: 1.6;
    font-weight: 400;
  }
}
.form__label--accept {
  flex-basis: 100%;
  cursor: pointer;
}
.form__label--accept .form__accept-input:checked + .form__accept-label::before {
  background-color: #CC1111;
  background-image: url("../img/icons/stack.svg#check");
  background-size: 8px;
  background-repeat: no-repeat;
  background-position: center;
}
.form__label--accept .form__accept-label {
  font-size: 15px;
  line-height: 1.4;
  font-weight: 300;
  padding-left: 28px;
  position: relative;
  display: block;
}
@media (max-width: 1256px) {
  .form__label--accept .form__accept-label {
    font-size: 13.5px;
    line-height: 1.4;
    font-weight: 300;
  }
}
@media (max-width: 768px) {
  .form__label--accept .form__accept-label {
    font-size: 11.25px;
    line-height: 1.4;
    font-weight: 300;
  }
}
@media (max-width: 320px) {
  .form__label--accept .form__accept-label {
    font-size: 9px;
    line-height: 1.4;
    font-weight: 300;
  }
}
.form__label--accept .form__accept-label::before {
  position: absolute;
  content: "";
  width: 16px;
  height: 16px;
  border: 1px #CC1111 solid;
  border-radius: 4px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.form__label--accept .form__accept-link {
  color: #CC1111;
  font-weight: 500;
}
.form__button {
  padding: 28px;
  flex-grow: 1;
  max-width: 340px;
}
@media (max-width: 1256px) {
  .form__button {
    padding: 20px;
  }
}
.when {
  position: relative;
  padding-left: 16px;
}
.when::before {
  position: absolute;
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #12c868;
  top: 4px;
  left: 0;
}
.second-color {
  color: #FFFFFF;
}
.hero {
  background-image: image-set(url("../img/hero-background.webp") type("image/webp") 1x, url("../img/hero-background@2x.webp") type("image/webp") 2x, url("../img/hero-background.jpg") 1x, url("../img/hero-background@2x.jpg") 2x);
  background-color: #484343;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
  min-height: 970px;
}
@media (max-width: 1256px) {
  .hero::before {
    position: absolute;
    background: linear-gradient(to bottom, rgba(110, 110, 110, 0) 40%, #2b2725 65%);
    content: "";
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}
@media (max-width: 1256px) and (max-width: 1100px) {
  .hero::before {
    background: linear-gradient(180deg, rgba(110, 110, 110, 0) 40%, #2b2725 61%);
  }
}
@media (max-width: 1256px) and (max-width: 980px) {
  .hero::before {
    background: linear-gradient(180deg, rgba(110, 110, 110, 0) 40%, #2b2725 59%);
  }
}
@media (max-width: 1256px) and (max-width: 880px) {
  .hero::before {
    background: linear-gradient(180deg, rgba(110, 110, 110, 0) 40%, #2b2725 57%);
  }
}
@media (max-width: 1256px) and (max-width: 810px) {
  .hero::before {
    background: linear-gradient(180deg, rgba(110, 110, 110, 0) 40%, #2b2725 55%);
  }
}
@media (max-width: 1256px) and (max-width: 780px) {
  .hero::before {
    background: linear-gradient(180deg, rgba(110, 110, 110, 0) 40%, #2b2725 53%);
  }
}
@media (max-width: 1256px) and (max-width: 768px) {
  .hero::before {
    background: linear-gradient(180deg, rgba(110, 110, 110, 0) 40%, #2b2725 59%);
  }
}
@media (max-width: 1256px) and (max-width: 680px) {
  .hero::before {
    background: linear-gradient(180deg, rgba(110, 110, 110, 0) 40%, #2b2725 55%);
  }
}
@media (max-width: 1256px) and (max-width: 600px) {
  .hero::before {
    background: linear-gradient(180deg, rgba(110, 110, 110, 0) 40%, #2b2725 51%);
  }
}
.hero__wrapper {
  display: flex;
  position: relative;
  padding-top: 220px;
  padding-bottom: 212px;
}
@media (max-width: 1256px) {
  .hero__wrapper {
    padding-bottom: 100px;
    flex-direction: column;
  }
}
@media (max-width: 768px) {
  .hero__wrapper {
    padding-bottom: 60px;
  }
}
@media (max-width: 320px) {
  .hero__wrapper {
    padding-bottom: 40px;
  }
}
.hero__description-wrapper {
  max-width: 770px;
  position: relative;
  z-index: 1;
}
.hero__title {
  font-size: 64px;
  line-height: 1.15;
  font-weight: 300;
  margin-bottom: 40px;
}
@media (max-width: 1256px) {
  .hero__title {
    font-size: 57.6px;
    line-height: 1.15;
    font-weight: 300;
  }
}
@media (max-width: 768px) {
  .hero__title {
    font-size: 48px;
    line-height: 1.15;
    font-weight: 300;
  }
}
@media (max-width: 320px) {
  .hero__title {
    font-size: 38.4px;
    line-height: 1.15;
    font-weight: 300;
  }
}
.hero__title .price {
  color: #cecece;
  padding: 16px 24px;
  font-size: 20px;
  line-height: 1.15;
  font-weight: 600;
  background-color: rgba(255, 255, 255, 0.16);
  border-radius: 20px;
  vertical-align: middle;
  text-wrap: nowrap;
}
@media (max-width: 1256px) {
  .hero__title .price {
    font-size: 18px;
    line-height: 1.15;
    font-weight: 600;
  }
}
@media (max-width: 768px) {
  .hero__title .price {
    font-size: 15px;
    line-height: 1.15;
    font-weight: 600;
  }
}
@media (max-width: 320px) {
  .hero__title .price {
    font-size: 12px;
    line-height: 1.15;
    font-weight: 600;
  }
}
.hero__description {
  font-size: 22px;
  line-height: 1.5;
  font-weight: 500;
  position: relative;
  max-width: 491px;
  padding-left: 64px;
  margin-bottom: 40px;
  color: #808080;
}
@media (max-width: 1256px) {
  .hero__description {
    font-size: 19.8px;
    line-height: 1.5;
    font-weight: 500;
  }
}
@media (max-width: 768px) {
  .hero__description {
    font-size: 16.5px;
    line-height: 1.5;
    font-weight: 500;
  }
}
@media (max-width: 320px) {
  .hero__description {
    font-size: 13.2px;
    line-height: 1.5;
    font-weight: 500;
  }
}
.hero__description::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 48px;
  height: 26px;
  background-image: url("../img/icons/stack.svg#dots");
}
.hero__owner-wrapper {
  position: absolute;
  bottom: -2%;
  right: 0;
}
@media (max-width: 1256px) {
  .hero__owner-wrapper {
    position: relative;
  }
}
@media (max-width: 480px) {
  .hero__owner-wrapper {
    margin-bottom: 60px;
  }
}
.hero__owner-image {
  position: absolute;
  bottom: 0;
  right: -140px;
  z-index: 0;
}
@media (max-width: 1256px) {
  .hero__owner-image {
    position: initial;
    width: 100%;
    height: auto;
  }
}
.hero__owner-description-wrapper {
  padding: 20px;
  background-color: rgba(31, 31, 36, 0.52);
  border-radius: 20px;
  position: absolute;
  z-index: 1;
  backdrop-filter: blur(5px);
  max-width: 300px;
  bottom: 177px;
  right: 10%;
  min-width: 300px;
}
@media (max-width: 1256px) {
  .hero__owner-description-wrapper {
    top: 10%;
    left: 10%;
    bottom: initial;
    right: initial;
  }
}
@media (max-width: 768px) {
  .hero__owner-description-wrapper {
    min-width: 150px;
  }
}
@media (max-width: 680px) {
  .hero__owner-description-wrapper {
    left: 0;
  }
}
@media (max-width: 580px) {
  .hero__owner-description-wrapper {
    top: 0;
  }
}
@media (max-width: 480px) {
  .hero__owner-description-wrapper {
    top: initial;
    left: initial;
    right: 20px;
    bottom: -45px;
    backdrop-filter: blur(20px);
  }
}
@media (max-width: 380px) {
  .hero__owner-description-wrapper {
    top: initial;
    left: initial;
    right: 15px;
    bottom: -45px;
  }
}
.hero__owner-name {
  font-size: 24px;
  line-height: 1.3;
  font-weight: 500;
  margin-bottom: 16px;
}
@media (max-width: 1256px) {
  .hero__owner-name {
    font-size: 21.6px;
    line-height: 1.3;
    font-weight: 500;
  }
}
@media (max-width: 768px) {
  .hero__owner-name {
    font-size: 18px;
    line-height: 1.3;
    font-weight: 500;
  }
}
@media (max-width: 320px) {
  .hero__owner-name {
    font-size: 14.4px;
    line-height: 1.3;
    font-weight: 500;
  }
}
.hero__owner-description {
  font-size: 16px;
  line-height: 1.6;
  font-weight: 400;
  opacity: 0.8;
}
@media (max-width: 1256px) {
  .hero__owner-description {
    font-size: 14.4px;
    line-height: 1.6;
    font-weight: 400;
  }
}
@media (max-width: 768px) {
  .hero__owner-description {
    font-size: 12px;
    line-height: 1.6;
    font-weight: 400;
  }
}
@media (max-width: 320px) {
  .hero__owner-description {
    font-size: 9.6px;
    line-height: 1.6;
    font-weight: 400;
  }
}
.conditions {
  padding-top: 120px;
  padding-bottom: 120px;
}
@media (max-width: 1256px) {
  .conditions {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
@media (max-width: 768px) {
  .conditions {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
@media (max-width: 320px) {
  .conditions {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
.conditions__title {
  margin-bottom: 60px;
}
.conditions__list {
  display: flex;
  gap: 32px;
  flex-wrap: wrap;
}
@media (max-width: 1600px) {
  .conditions__list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(600px, 1fr));
  }
}
@media (max-width: 767px) {
  .conditions__list {
    gap: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }
}
.conditions__item {
  padding: 40px;
  background-color: #F9F9F9;
  min-height: 300px;
  border-radius: 24px;
  background-image: image-set(url("../img/conditions__item--closet.webp") type("image/webp") 1x, url("../img/conditions__item--closet@2x.webp") type("image/webp") 2x, url("../img/conditions__item--closet.png") 1x, url("../img/conditions__item--closet@2x.png") 2x);
  background-repeat: no-repeat;
  background-position: right bottom;
  min-width: calc(52% - 32px);
}
@media (max-width: 1600px) {
  .conditions__item {
    min-width: initial;
    min-height: initial;
  }
}
@media (max-width: 768px) {
  .conditions__item {
    padding: 20px;
  }
}
.conditions__item--document {
  background-image: image-set(url("../img/conditions__item--document.webp") type("image/webp") 1x, url("../img/conditions__item--document@2x.webp") type("image/webp") 2x, url("../img/conditions__item--document.png") 1x, url("../img/conditions__item--document@2x.png") 2x);
}
.conditions__item--details {
  background-image: image-set(url("../img/conditions__item--details.webp") type("image/webp") 1x, url("../img/conditions__item--details@2x.webp") type("image/webp") 2x, url("../img/conditions__item--details.png") 1x, url("../img/conditions__item--details@2x.png") 2x);
}
.conditions__item--computer {
  background-image: image-set(url("../img/conditions__item--computer.webp") type("image/webp") 1x, url("../img/conditions__item--computer@2x.webp") type("image/webp") 2x, url("../img/conditions__item--computer.png") 1x, url("../img/conditions__item--computer@2x.png") 2x);
}
@media (max-width: 767px) {
  .conditions__item {
    background-image: initial;
  }
}
.conditions__item:nth-child(3n+1) {
  min-width: calc(48% - 32px);
}
@media (max-width: 1600px) {
  .conditions__item:nth-child(3n+1) {
    min-width: initial;
  }
}
.conditions__item-title {
  font-size: 26px;
  line-height: 1.2;
  font-weight: 300;
  margin-bottom: 20px;
  max-width: 340px;
}
@media (max-width: 1256px) {
  .conditions__item-title {
    font-size: 23.4px;
    line-height: 1.2;
    font-weight: 300;
  }
}
@media (max-width: 768px) {
  .conditions__item-title {
    font-size: 19.5px;
    line-height: 1.2;
    font-weight: 300;
  }
}
@media (max-width: 320px) {
  .conditions__item-title {
    font-size: 15.6px;
    line-height: 1.2;
    font-weight: 300;
  }
}
.conditions__description {
  font-size: 16px;
  line-height: 1.6;
  font-weight: 300;
  max-width: 352px;
}
@media (max-width: 1256px) {
  .conditions__description {
    font-size: 14.4px;
    line-height: 1.6;
    font-weight: 300;
  }
}
@media (max-width: 768px) {
  .conditions__description {
    font-size: 12px;
    line-height: 1.6;
    font-weight: 300;
  }
}
@media (max-width: 320px) {
  .conditions__description {
    font-size: 9.6px;
    line-height: 1.6;
    font-weight: 300;
  }
}
@media (max-width: 1600px) {
  .conditions__description {
    max-width: 50%;
  }
}
@media (max-width: 767px) {
  .conditions__description {
    max-width: initial;
  }
}
.advantages {
  padding-top: 120px;
  margin-bottom: -350px;
  position: relative;
  z-index: 1;
}
@media (max-width: 1800px) {
  .advantages {
    margin-bottom: -250px;
  }
}
@media (max-width: 1256px) {
  .advantages {
    margin-bottom: 0;
    padding: 120px 0;
  }
}
@media (max-width: 768px) {
  .advantages {
    padding-top: 60px;
    padding-bottom: 60px;
    margin-bottom: 0;
  }
}
@media (max-width: 320px) {
  .advantages {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
.advantages__title {
  margin-bottom: 40px;
}
.advantages__list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 40px;
  margin-bottom: 60px;
}
@media (max-width: 1256px) {
  .advantages__list {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }
}
.advantages__item {
  font-size: 22px;
  line-height: 1.4;
  font-weight: 300;
  padding-top: 32px;
  position: relative;
}
@media (max-width: 1256px) {
  .advantages__item {
    font-size: 19.8px;
    line-height: 1.4;
    font-weight: 300;
  }
}
@media (max-width: 768px) {
  .advantages__item {
    font-size: 16.5px;
    line-height: 1.4;
    font-weight: 300;
  }
}
@media (max-width: 320px) {
  .advantages__item {
    font-size: 13.2px;
    line-height: 1.4;
    font-weight: 300;
  }
}
.advantages__item::before,
.advantages__item::after {
  content: "";
  position: absolute;
  top: 7px;
  left: 0;
  background-color: #CC1111;
  width: 14px;
  height: 2px;
}
.advantages__item::after {
  transform: rotate(90deg);
}
.advantages__video {
  border-radius: 60px;
}
@media (max-width: 1256px) {
  .advantages__video {
    height: 700px;
  }
}
@media (max-width: 768px) {
  .advantages__video {
    height: 500px;
  }
}
@media (max-width: 320px) {
  .advantages__video {
    height: 300px;
  }
}
.our-projects {
  margin-bottom: -152px;
  background-image: image-set(url("../img/our-projects-background.webp") type("image/webp") 1x, url("../img/our-projects-background@2x.webp") type("image/webp") 2x, url("../img/our-projects-background.jpg") 1x, url("../img/our-projects-background@2x.jpg") 2x);
  background-color: #484343;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 120px 0 272px;
}
@media (max-width: 768px) {
  .our-projects {
    padding-top: 60px;
    padding-bottom: 60px;
    margin-bottom: 60px;
  }
}
@media (max-width: 320px) {
  .our-projects {
    padding-top: 40px;
    padding-bottom: 40px;
    margin-bottom: 40px;
  }
}
.our-projects__wrapper {
  display: flex;
  gap: 20px;
  margin-bottom: 60px;
  align-items: center;
  flex-wrap: wrap;
}
.our-projects__title {
  max-width: 1200px;
}
.our-projects__hint {
  font-size: 16px;
  line-height: 1.4;
  font-weight: 300;
  opacity: 0.6;
  padding-left: 52px;
  width: 320px;
  position: relative;
}
@media (max-width: 1256px) {
  .our-projects__hint {
    font-size: 14.4px;
    line-height: 1.4;
    font-weight: 300;
  }
}
@media (max-width: 768px) {
  .our-projects__hint {
    font-size: 12px;
    line-height: 1.4;
    font-weight: 300;
  }
}
@media (max-width: 320px) {
  .our-projects__hint {
    font-size: 9.6px;
    line-height: 1.4;
    font-weight: 300;
  }
}
.our-projects__hint::before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 32px;
  height: 32px;
  background-image: url("../img/icons/stack.svg#hand");
}
.slider {
  overflow: hidden;
}
.slider .container {
  position: relative;
}
.slider .container--slider {
  padding: 0 40px;
}
@media (max-width: 1256px) {
  .slider .container--slider {
    padding: 0 40px;
  }
}
@media (max-width: 768px) {
  .slider .container--slider {
    padding: 0 20px;
  }
}
@media (max-width: 500px) {
  .slider .container--slider {
    padding: 0 10px;
  }
}
.slider__button-wrapper {
  max-width: 120px;
  min-width: 120px;
  display: flex;
  gap: 20px;
}
.slider__button-wrapper--main {
  position: absolute;
  right: 80px;
  z-index: 1;
}
@media (max-width: 768px) {
  .slider__button-wrapper--main {
    right: 20px;
  }
}
@media (max-width: 400px) {
  .slider__button-wrapper--main {
    top: 20px;
  }
}
@media (max-width: 600px) {
  .slider__button-wrapper {
    max-width: 90px;
    min-width: 90px;
    gap: 10px;
  }
}
@media (max-width: 400px) {
  .slider__button-wrapper {
    max-width: 65px;
    min-width: 65px;
    gap: 5px;
  }
}
.slider__button {
  width: 50px;
  height: 50px;
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  background-image: url("../img/icons/stack.svg#arrow");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 16px;
}
.slider__button--prev {
  transform: rotate(180deg);
}
@media (max-width: 600px) {
  .slider__button {
    width: 40px;
    height: 40px;
    background-size: 13px;
  }
}
@media (max-width: 400px) {
  .slider__button {
    width: 30px;
    height: 30px;
    background-size: 10px;
  }
}
.slider__list {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  gap: 40px;
  transition: transform 0.5s ease-in-out;
}
.slider__item {
  display: flex;
  flex-direction: column;
  min-width: 100%;
}
.slider__item--active .slider__description-wrapper {
  opacity: 1;
}
.slider__item--active .slider__mini::before {
  opacity: 0;
  z-index: -1;
}
.slider__name-wrapper {
  display: grid;
  row-gap: 12px;
}
@media (max-width: 1256px) {
  .slider__name-wrapper {
    padding-right: 120px;
  }
}
@media (max-width: 500px) {
  .slider__name-wrapper {
    padding-right: 80px;
  }
}
@media (max-width: 320px) {
  .slider__name-wrapper {
    padding-right: 65px;
  }
}
.slider__description-wrapper {
  padding: 0 40px;
  display: flex;
  gap: 60px;
  width: 100%;
  margin-bottom: 40px;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  padding-right: 160px;
}
@media (max-width: 1400px) {
  .slider__description-wrapper {
    flex-direction: column;
    gap: 40px;
    padding: 0 20px;
  }
}
@media (max-width: 320px) {
  .slider__description-wrapper {
    gap: 20px;
  }
}
.slider__description-list {
  display: grid;
  gap: 12px 40px;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: repeat(4, 1fr);
}
@media (max-width: 600px) {
  .slider__description-list {
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: row dense;
    gap: 0 20px;
  }
  .slider__description-list .slider__description-number:nth-of-type(odd) {
    grid-column: 1 / 2;
    grid-row: initial;
  }
  .slider__description-list .slider__description-number:nth-of-type(even) {
    grid-column: 2 / 3;
    grid-row: initial;
  }
  .slider__description-list .slider__definition:nth-of-type(even) {
    grid-column: 2 / 3;
  }
  .slider__description-list .slider__definition:nth-of-type(odd) {
    grid-column: 1 / 2;
  }
}
.slider__description-number {
  grid-row: 1 / 2;
}
.slider__description-number,
.slider__name {
  font-size: 28px;
  line-height: 1.2;
  font-weight: 500;
}
@media (max-width: 1256px) {
  .slider__description-number,
  .slider__name {
    font-size: 25.2px;
    line-height: 1.2;
    font-weight: 500;
  }
}
@media (max-width: 768px) {
  .slider__description-number,
  .slider__name {
    font-size: 21px;
    line-height: 1.2;
    font-weight: 500;
  }
}
@media (max-width: 320px) {
  .slider__description-number,
  .slider__name {
    font-size: 16.8px;
    line-height: 1.2;
    font-weight: 500;
  }
}
.slider__definition,
.slider__city {
  font-size: 18px;
  line-height: 1.2;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.7);
}
@media (max-width: 1256px) {
  .slider__definition,
  .slider__city {
    font-size: 16.2px;
    line-height: 1.2;
    font-weight: 300;
  }
}
@media (max-width: 768px) {
  .slider__definition,
  .slider__city {
    font-size: 13.5px;
    line-height: 1.2;
    font-weight: 300;
  }
}
@media (max-width: 320px) {
  .slider__definition,
  .slider__city {
    font-size: 10.8px;
    line-height: 1.2;
    font-weight: 300;
  }
}
.slider__mini {
  border-radius: 60px;
  position: relative;
  width: 100%;
  display: block;
  max-width: 1640px;
  overflow: hidden;
}
@media (max-width: 1256px) {
  .slider__mini {
    border-radius: 40px;
  }
}
@media (max-width: 768px) {
  .slider__mini {
    border-radius: 20px;
  }
}
@media (max-width: 500px) {
  .slider__mini {
    border-radius: 8px;
  }
}
.slider__mini::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(23, 24, 29, 0.7);
  backdrop-filter: blur(30px);
  z-index: 1;
  transition: opacity 0.5s ease-in-out;
  border-radius: 60px;
  overflow: hidden;
}
@media (max-width: 1256px) {
  .slider__mini::before {
    border-radius: 40px;
  }
}
@media (max-width: 768px) {
  .slider__mini::before {
    border-radius: 20px;
  }
}
@media (max-width: 500px) {
  .slider__mini::before {
    border-radius: 8px;
  }
}
.slider__mini .slider__button-wrapper {
  position: absolute;
  right: 40px;
  bottom: 40px;
}
.slider__mini .slider__button-wrapper .slider__button {
  background-color: rgba(23, 24, 29, 0.3);
}
@media (max-width: 768px) {
  .slider__mini .slider__button-wrapper {
    right: 20px;
    bottom: 20px;
  }
}
@media (max-width: 400px) {
  .slider__mini .slider__button-wrapper {
    right: 10px;
    bottom: 10px;
  }
}
.slider__photos {
  display: flex;
  transition: transform 0.5s ease-in-out;
}
.slider__photo {
  min-width: 100%;
}
.slider__image {
  width: 100%;
  height: 100%;
}
.price-form {
  padding: 60px;
  box-shadow: 0 40px 60px -24px rgba(0, 0, 0, 0.08);
  border-radius: 60px;
  display: grid;
  grid-template-columns: 1fr 4fr;
  gap: 40px;
  position: relative;
  z-index: 1;
  background-color: #FFFFFF;
}
@media (max-width: 1256px) {
  .price-form {
    grid-template-columns: initial;
    padding: 52px;
  }
}
@media (max-width: 768px) {
  .price-form {
    padding: 36px;
  }
}
@media (max-width: 500px) {
  .price-form {
    padding: 0;
    box-shadow: initial;
  }
}
.price-form__description-wrapper {
  grid-column: 1 / 2;
  grid-row: 1 / 3;
}
.price-form__title {
  font-size: 40px;
  line-height: 1.2;
  font-weight: 600;
  margin-bottom: 32px;
}
@media (max-width: 1256px) {
  .price-form__title {
    font-size: 36px;
    line-height: 1.2;
    font-weight: 600;
  }
}
@media (max-width: 768px) {
  .price-form__title {
    font-size: 30px;
    line-height: 1.2;
    font-weight: 600;
  }
}
@media (max-width: 320px) {
  .price-form__title {
    font-size: 24px;
    line-height: 1.2;
    font-weight: 600;
  }
}
.price-form__description {
  font-size: 24px;
  line-height: 1.4;
  font-weight: 300;
}
@media (max-width: 1256px) {
  .price-form__description {
    font-size: 21.6px;
    line-height: 1.4;
    font-weight: 300;
  }
}
@media (max-width: 768px) {
  .price-form__description {
    font-size: 18px;
    line-height: 1.4;
    font-weight: 300;
  }
}
@media (max-width: 320px) {
  .price-form__description {
    font-size: 14.4px;
    line-height: 1.4;
    font-weight: 300;
  }
}
.price-form__list {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
}
.price-form__item {
  font-size: 16px;
  line-height: 1.6;
  font-weight: 400;
  max-width: 340px;
  min-width: 340px;
  padding-left: 68px;
  position: relative;
}
@media (max-width: 1256px) {
  .price-form__item {
    font-size: 14.4px;
    line-height: 1.6;
    font-weight: 400;
  }
}
@media (max-width: 768px) {
  .price-form__item {
    font-size: 12px;
    line-height: 1.6;
    font-weight: 400;
  }
}
@media (max-width: 320px) {
  .price-form__item {
    font-size: 9.6px;
    line-height: 1.6;
    font-weight: 400;
  }
}
@media (max-width: 500px) {
  .price-form__item {
    min-width: initial;
  }
}
.price-form__item::before {
  position: absolute;
  content: "";
  width: 48px;
  height: 48px;
  border: 1px #CC1111 solid;
  border-radius: 50%;
  left: 0;
  top: 0;
  background-image: url("../img/icons/stack.svg#red-decoratioin-tick");
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: center;
}
.experience {
  background-color: #484343;
  position: relative;
}
@media (max-width: 580px) {
  .experience {
    padding-bottom: 40px;
  }
  .experience::before {
    position: absolute;
    background: linear-gradient(to bottom, rgba(110, 110, 110, 0) 16%, #2b2725 63%);
    content: "";
    width: 100%;
    height: 100%;
  }
}
@media (max-width: 580px) and (max-width: 480px) {
  .experience::before {
    background: linear-gradient(180deg, rgba(110, 110, 110, 0) 11%, #2b2725 57%);
  }
}
@media (max-width: 580px) and (max-width: 440px) {
  .experience::before {
    background: linear-gradient(180deg, rgba(110, 110, 110, 0) 10%, #2b2725 52%);
  }
}
@media (max-width: 580px) and (max-width: 400px) {
  .experience::before {
    background: linear-gradient(180deg, rgba(110, 110, 110, 0) 10%, #2b2725 50%);
  }
}
@media (max-width: 580px) and (max-width: 380px) {
  .experience::before {
    background: linear-gradient(180deg, rgba(110, 110, 110, 0) 11%, #2b2725 47%);
  }
}
.experience__description-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 536px;
  margin-top: auto;
  position: absolute;
  bottom: 380px;
}
@media (max-width: 1024px) {
  .experience__description-wrapper {
    top: 60px;
  }
}
@media (max-width: 768px) {
  .experience__description-wrapper {
    top: 40px;
  }
}
@media (max-width: 580px) {
  .experience__description-wrapper {
    position: initial;
  }
}
.experience__title {
  font-weight: 600;
  margin-bottom: 40px;
}
@media (max-width: 440px) {
  .experience__title {
    margin-bottom: 20px;
  }
}
.experience__title::before {
  display: none;
}
.experience__title .eye-catching {
  display: block;
  font-size: 74px;
  line-height: 1.3;
  font-weight: 300;
}
@media (max-width: 1256px) {
  .experience__title .eye-catching {
    font-size: 66.6px;
    line-height: 1.3;
    font-weight: 300;
  }
}
@media (max-width: 768px) {
  .experience__title .eye-catching {
    font-size: 55.5px;
    line-height: 1.3;
    font-weight: 300;
  }
}
@media (max-width: 320px) {
  .experience__title .eye-catching {
    font-size: 44.4px;
    line-height: 1.3;
    font-weight: 300;
  }
}
@media (max-width: 1400px) {
  .experience__title .eye-catching {
    font-size: 60px;
    line-height: 1.3;
    font-weight: 300;
  }
}
@media (max-width: 1400px) and (max-width: 1256px) {
  .experience__title .eye-catching {
    font-size: 54px;
    line-height: 1.3;
    font-weight: 300;
  }
}
@media (max-width: 1400px) and (max-width: 768px) {
  .experience__title .eye-catching {
    font-size: 45px;
    line-height: 1.3;
    font-weight: 300;
  }
}
@media (max-width: 1400px) and (max-width: 320px) {
  .experience__title .eye-catching {
    font-size: 36px;
    line-height: 1.3;
    font-weight: 300;
  }
}
@media (max-width: 580px) {
  .experience__title .eye-catching {
    font-size: 40px;
    line-height: 1.3;
    font-weight: 300;
  }
}
@media (max-width: 580px) and (max-width: 1256px) {
  .experience__title .eye-catching {
    font-size: 36px;
    line-height: 1.3;
    font-weight: 300;
  }
}
@media (max-width: 580px) and (max-width: 768px) {
  .experience__title .eye-catching {
    font-size: 30px;
    line-height: 1.3;
    font-weight: 300;
  }
}
@media (max-width: 580px) and (max-width: 320px) {
  .experience__title .eye-catching {
    font-size: 24px;
    line-height: 1.3;
    font-weight: 300;
  }
}
.experience__description {
  font-size: 24px;
  line-height: 1.36;
  font-weight: 400;
  padding-left: 74px;
  position: relative;
  max-width: 440px;
}
@media (max-width: 1256px) {
  .experience__description {
    font-size: 21.6px;
    line-height: 1.36;
    font-weight: 400;
  }
}
@media (max-width: 768px) {
  .experience__description {
    font-size: 18px;
    line-height: 1.36;
    font-weight: 400;
  }
}
@media (max-width: 320px) {
  .experience__description {
    font-size: 14.4px;
    line-height: 1.36;
    font-weight: 400;
  }
}
@media (max-width: 1400px) {
  .experience__description {
    font-size: 20px;
    line-height: 1.3;
    font-weight: 300;
  }
}
@media (max-width: 1400px) and (max-width: 1256px) {
  .experience__description {
    font-size: 18px;
    line-height: 1.3;
    font-weight: 300;
  }
}
@media (max-width: 1400px) and (max-width: 768px) {
  .experience__description {
    font-size: 15px;
    line-height: 1.3;
    font-weight: 300;
  }
}
@media (max-width: 1400px) and (max-width: 320px) {
  .experience__description {
    font-size: 12px;
    line-height: 1.3;
    font-weight: 300;
  }
}
@media (max-width: 1024px) {
  .experience__description {
    max-width: 360px;
    padding-left: 40px;
  }
}
@media (max-width: 768px) {
  .experience__description {
    max-width: 200px;
  }
}
@media (max-width: 580px) {
  .experience__description {
    max-width: initial;
  }
}
.experience__description::before {
  position: absolute;
  content: "";
  width: 50px;
  height: 2px;
  background-color: #CC1111;
  border-radius: 10px;
  left: 0;
  top: 20px;
}
@media (max-width: 1024px) {
  .experience__description::before {
    width: 28px;
  }
}
.steps {
  padding: 120px 0;
}
@media (max-width: 1256px) {
  .steps {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
@media (max-width: 768px) {
  .steps {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
@media (max-width: 320px) {
  .steps {
    padding: 40px 0;
  }
}
.steps__inner {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 1256px) {
  .steps__inner {
    flex-direction: column;
  }
}
.steps__description-wrapper {
  margin-right: 80px;
  margin-bottom: 20px;
  max-width: 512px;
}
@media (max-width: 1256px) {
  .steps__description-wrapper {
    max-width: initial;
    margin-right: 0;
  }
}
.steps__title {
  margin-bottom: 32px;
}
.steps__description {
  font-size: 24px;
  line-height: 1.4;
  font-weight: 300;
  margin-bottom: 32px;
}
@media (max-width: 1256px) {
  .steps__description {
    font-size: 21.6px;
    line-height: 1.4;
    font-weight: 300;
  }
}
@media (max-width: 768px) {
  .steps__description {
    font-size: 18px;
    line-height: 1.4;
    font-weight: 300;
  }
}
@media (max-width: 320px) {
  .steps__description {
    font-size: 14.4px;
    line-height: 1.4;
    font-weight: 300;
  }
}
.steps__list {
  display: flex;
  flex-direction: column;
  row-gap: 80px;
  counter-reset: num;
}
@media (max-width: 1256px) {
  .steps__list {
    row-gap: 40px;
  }
}
.steps__item {
  padding-left: 72px;
  position: relative;
}
.steps__item::before {
  position: absolute;
  content: counter(num);
  counter-increment: num;
  color: #CC1111;
  font-size: 32px;
  line-height: 1.05;
  font-weight: 300;
  top: 0;
  left: 0;
}
@media (max-width: 1256px) {
  .steps__item::before {
    font-size: 28.8px;
    line-height: 1.05;
    font-weight: 300;
  }
}
@media (max-width: 768px) {
  .steps__item::before {
    font-size: 24px;
    line-height: 1.05;
    font-weight: 300;
  }
}
@media (max-width: 320px) {
  .steps__item::before {
    font-size: 19.2px;
    line-height: 1.05;
    font-weight: 300;
  }
}
.steps__item:nth-child(-n+9)::before {
  content: "0" counter(num);
}
.steps__item-title {
  font-size: 22px;
  line-height: 1.2;
  font-weight: 600;
  margin-bottom: 12px;
}
@media (max-width: 1256px) {
  .steps__item-title {
    font-size: 19.8px;
    line-height: 1.2;
    font-weight: 600;
  }
}
@media (max-width: 768px) {
  .steps__item-title {
    font-size: 16.5px;
    line-height: 1.2;
    font-weight: 600;
  }
}
@media (max-width: 320px) {
  .steps__item-title {
    font-size: 13.2px;
    line-height: 1.2;
    font-weight: 600;
  }
}
.steps__item-description {
  font-size: 16px;
  line-height: 1.4;
  font-weight: 300;
}
@media (max-width: 1256px) {
  .steps__item-description {
    font-size: 14.4px;
    line-height: 1.4;
    font-weight: 300;
  }
}
@media (max-width: 768px) {
  .steps__item-description {
    font-size: 12px;
    line-height: 1.4;
    font-weight: 300;
  }
}
@media (max-width: 320px) {
  .steps__item-description {
    font-size: 9.6px;
    line-height: 1.4;
    font-weight: 300;
  }
}
.steps__item-description .eye-catching {
  font-weight: 500;
}
.history {
  background-color: #484343;
  background-image: image-set(url("../img/history-background.webp") type("image/webp") 1x, url("../img/history-background@2x.webp") type("image/webp") 2x, url("../img/history-background.jpg") 1x, url("../img/history-background@2x.jpg") 2x);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
}
@media (max-width: 1256px) {
  .history::before {
    position: absolute;
    background: linear-gradient(to bottom, rgba(110, 110, 110, 0) 16%, #2b2725 75%);
    content: "";
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}
@media (max-width: 1256px) and (max-width: 980px) {
  .history::before {
    background: linear-gradient(180deg, rgba(110, 110, 110, 0) 40%, #2b2725 72%);
  }
}
@media (max-width: 1256px) and (max-width: 880px) {
  .history::before {
    background: linear-gradient(180deg, rgba(110, 110, 110, 0) 40%, #2b2725 68%);
  }
}
@media (max-width: 1256px) and (max-width: 580px) {
  .history::before {
    background: linear-gradient(180deg, rgba(110, 110, 110, 0) 40%, #2b2725 63%);
  }
}
@media (max-width: 1256px) and (max-width: 480px) {
  .history::before {
    background: linear-gradient(180deg, rgba(110, 110, 110, 0) 40%, #2b2725 60%);
  }
}
@media (max-width: 1256px) and (max-width: 400px) {
  .history::before {
    background: linear-gradient(180deg, rgba(110, 110, 110, 0) 40%, #2b2725 57%);
  }
}
@media (max-width: 1256px) and (max-width: 350px) {
  .history::before {
    background: linear-gradient(180deg, rgba(110, 110, 110, 0) 40%, #2b2725 54%);
  }
}
.history__title {
  max-width: 50%;
  margin-bottom: 80px;
}
@media (max-width: 1256px) {
  .history__title {
    max-width: initial;
  }
}
@media (max-width: 880px) {
  .history__title {
    margin-bottom: 40px;
  }
}
.history__description-wrapper {
  font-size: 22px;
  line-height: 1.6;
  font-weight: 400;
  max-width: 566px;
  padding-left: 72px;
  position: relative;
}
@media (max-width: 1256px) {
  .history__description-wrapper {
    font-size: 19.8px;
    line-height: 1.6;
    font-weight: 400;
  }
}
@media (max-width: 768px) {
  .history__description-wrapper {
    font-size: 16.5px;
    line-height: 1.6;
    font-weight: 400;
  }
}
@media (max-width: 320px) {
  .history__description-wrapper {
    font-size: 13.2px;
    line-height: 1.6;
    font-weight: 400;
  }
}
@media (max-width: 1256px) {
  .history__description-wrapper {
    max-width: initial;
    z-index: 1;
  }
}
@media (max-width: 480px) {
  .history__description-wrapper {
    padding-left: 48px;
  }
}
.history__description-wrapper::before {
  position: absolute;
  content: "";
  width: 44px;
  height: 44px;
  left: 0;
  top: 0;
  background-image: url("../img/icons/stack.svg#history-quote");
}
@media (max-width: 480px) {
  .history__description-wrapper::before {
    width: 33px;
    height: 33px;
  }
}
.history__inner {
  padding: 160px 0 260px;
  position: relative;
}
@media (max-width: 1256px) {
  .history__inner {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
@media (max-width: 768px) {
  .history__inner {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
@media (max-width: 320px) {
  .history__inner {
    padding: 40px 0;
  }
}
.history__image {
  position: absolute;
  bottom: -20px;
  right: -180px;
  z-index: -1;
}
@media (max-width: 1256px) {
  .history__image {
    position: initial;
  }
}
.our-team {
  padding: 220px 0;
  margin-top: -100px;
  background-color: #F9F9F9;
}
@media (max-width: 768px) {
  .our-team {
    padding-top: 60px;
    padding-bottom: 60px;
    margin: 0;
  }
}
@media (max-width: 320px) {
  .our-team {
    padding: 40px 0;
  }
}
.our-team__title {
  margin-bottom: 20px;
  max-width: 60%;
}
@media (max-width: 1256px) {
  .our-team__title {
    max-width: 80%;
  }
}
@media (max-width: 768px) {
  .our-team__title {
    max-width: initial;
  }
}
.our-team__description {
  margin-bottom: 60px;
  max-width: 50%;
  font-size: 24px;
}
@media (max-width: 1256px) {
  .our-team__description {
    max-width: 80%;
  }
}
@media (max-width: 768px) {
  .our-team__description {
    max-width: initial;
  }
}
.our-team__list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 40px;
}
@media (max-width: 1256px) {
  .our-team__list {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }
}
.our-team__item {
  text-align: center;
}
.our-team__image {
  background-color: #FFFFFF;
  border-radius: 40px;
  object-fit: contain;
  width: 100%;
  margin-bottom: 20px;
  object-position: center bottom;
}
.our-team__name {
  font-size: 20px;
  line-height: 1.3;
  font-weight: 600;
  margin-bottom: 8px;
}
@media (max-width: 1256px) {
  .our-team__name {
    font-size: 18px;
    line-height: 1.3;
    font-weight: 600;
  }
}
@media (max-width: 768px) {
  .our-team__name {
    font-size: 15px;
    line-height: 1.3;
    font-weight: 600;
  }
}
@media (max-width: 320px) {
  .our-team__name {
    font-size: 12px;
    line-height: 1.3;
    font-weight: 600;
  }
}
.our-team__post,
.our-team__experience {
  font-size: 15px;
  line-height: 1.6;
  font-weight: 300;
  color: #808080;
}
@media (max-width: 1256px) {
  .our-team__post,
  .our-team__experience {
    font-size: 13.5px;
    line-height: 1.6;
    font-weight: 300;
  }
}
@media (max-width: 768px) {
  .our-team__post,
  .our-team__experience {
    font-size: 11.25px;
    line-height: 1.6;
    font-weight: 300;
  }
}
@media (max-width: 320px) {
  .our-team__post,
  .our-team__experience {
    font-size: 9px;
    line-height: 1.6;
    font-weight: 300;
  }
}
.our-team .eye-catching {
  font-weight: 600;
  color: #17181D;
}
.contacts {
  background-color: #484343;
  background-image: image-set(url("../img/contacts-background.webp") type("image/webp") 1x, url("../img/contacts-background@2x.webp") type("image/webp") 2x, url("../img/contacts-background.jpg") 1x, url("../img/contacts-background@2x.jpg") 2x);
  background-position: center;
  background-size: cover;
  padding: 120px 0;
}
@media (max-width: 768px) {
  .contacts {
    padding: 60px 0;
  }
}
@media (max-width: 320px) {
  .contacts {
    padding: 40px 0;
  }
}
.contacts__inner {
  display: grid;
  gap: 60px;
  grid-template-columns: repeat(auto-fill, minmax(460px, 1fr));
  align-items: start;
}
@media (max-width: 1256px) {
  .contacts__inner {
    row-gap: 40px;
    column-gap: 28px;
  }
}
@media (max-width: 768px) {
  .contacts__inner {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 20px;
  }
}
.contacts__background {
  padding: 32px;
  border-radius: 40px;
  background-color: rgba(23, 24, 29, 0.4);
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.16);
}
@media (max-width: 768px) {
  .contacts__background {
    padding: 24px;
  }
}
@media (max-width: 1619px) {
  .contacts__description-wrapper {
    grid-column: 1 / -1;
  }
}
.contacts__title {
  margin-bottom: 40px;
}
.contacts__list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.contacts__item {
  position: relative;
  padding-left: 32px;
  font-size: 18px;
  line-height: 1.6;
  font-weight: 400;
}
@media (max-width: 1256px) {
  .contacts__item {
    font-size: 16.2px;
    line-height: 1.6;
    font-weight: 400;
  }
}
@media (max-width: 768px) {
  .contacts__item {
    font-size: 13.5px;
    line-height: 1.6;
    font-weight: 400;
  }
}
@media (max-width: 320px) {
  .contacts__item {
    font-size: 10.8px;
    line-height: 1.6;
    font-weight: 400;
  }
}
.contacts__item::after {
  position: absolute;
  content: "";
  width: 12px;
  height: 12px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-image: url("../img/icons/stack.svg#red-decoratioin-tick");
}
.contacts__wrapper-title {
  font-size: 16px;
  line-height: 1.2;
  font-weight: 700;
}
@media (max-width: 1256px) {
  .contacts__wrapper-title {
    font-size: 14.4px;
    line-height: 1.2;
    font-weight: 700;
  }
}
@media (max-width: 768px) {
  .contacts__wrapper-title {
    font-size: 12px;
    line-height: 1.2;
    font-weight: 700;
  }
}
@media (max-width: 320px) {
  .contacts__wrapper-title {
    font-size: 9.6px;
    line-height: 1.2;
    font-weight: 700;
  }
}
.contacts__wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  align-content: flex-start;
}
.contacts__wrapper .contacts__background {
  width: 100%;
}
.contacts__phone {
  font-style: inherit;
}
.contacts__phone-title {
  margin-bottom: 16px;
}
.contacts__phone-link {
  font-size: 32px;
  line-height: 1.2;
  font-weight: 700;
  display: block;
  color: inherit;
  margin-bottom: 8px;
}
@media (max-width: 1256px) {
  .contacts__phone-link {
    font-size: 28.8px;
    line-height: 1.2;
    font-weight: 700;
  }
}
@media (max-width: 768px) {
  .contacts__phone-link {
    font-size: 24px;
    line-height: 1.2;
    font-weight: 700;
  }
}
@media (max-width: 320px) {
  .contacts__phone-link {
    font-size: 19.2px;
    line-height: 1.2;
    font-weight: 700;
  }
}
.contacts__messengers-title {
  margin-bottom: 16px;
}
.contacts__messengers-wrapper {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(192px, 1fr));
}
.contacts__messengers-link {
  padding: 18px 40px;
  padding-left: 72px;
  font-size: 14px;
  line-height: 1.2;
  font-weight: 700;
  color: inherit;
  background-color: #05a0eb;
  border-radius: 100px;
  width: 100%;
  text-align: center;
}
@media (max-width: 1256px) {
  .contacts__messengers-link {
    font-size: 12.6px;
    line-height: 1.2;
    font-weight: 700;
  }
}
@media (max-width: 768px) {
  .contacts__messengers-link {
    font-size: 10.5px;
    line-height: 1.2;
    font-weight: 700;
  }
}
@media (max-width: 320px) {
  .contacts__messengers-link {
    font-size: 8.4px;
    line-height: 1.2;
    font-weight: 700;
  }
}
@media (max-width: 768px) {
  .contacts__messengers-link {
    padding: 16px 16px;
    padding-left: 56px;
    min-width: 140px;
  }
}
.contacts__messengers-link span {
  position: relative;
}
.contacts__messengers-link span::before {
  content: "";
  position: absolute;
  width: 21px;
  height: 17px;
  top: 50%;
  transform: translateY(-50%);
  left: -37px;
  background-image: url("../img/icons/stack.svg#telegram");
}
@media (max-width: 768px) {
  .contacts__messengers-link span::before {
    width: 15px;
    height: 12px;
    left: -32px;
  }
}
.contacts__messengers-link--whatsapp {
  background-color: #12c868;
}
.contacts__messengers-link--whatsapp span::before {
  background-image: url("../img/icons/stack.svg#whatsapp");
  width: 22px;
  height: 22px;
  left: -38px;
}
@media (max-width: 768px) {
  .contacts__messengers-link--whatsapp span::before {
    width: 16px;
    height: 16px;
    left: -33px;
  }
}
.contacts__social {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 40px;
}
.contacts__social-links-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 24px;
}
.contacts__social-link svg {
  vertical-align: middle;
}
.contacts__social-link--vkontakte {
  color: #0077ff;
}
.contacts__social-link--youtube {
  color: #ff0302;
}
.contacts__form {
  background-color: rgba(23, 24, 29, 0.49);
  box-shadow: 0 50px 60px -32px rgba(0, 0, 0, 0.4);
  padding: 60px;
}
@media (max-width: 768px) {
  .contacts__form {
    padding: 28px;
  }
}
.contacts__form-title {
  font-size: 32px;
  line-height: 1.2;
  font-weight: 600;
  margin-bottom: 8px;
}
@media (max-width: 1256px) {
  .contacts__form-title {
    font-size: 28.8px;
    line-height: 1.2;
    font-weight: 600;
  }
}
@media (max-width: 768px) {
  .contacts__form-title {
    font-size: 24px;
    line-height: 1.2;
    font-weight: 600;
  }
}
@media (max-width: 320px) {
  .contacts__form-title {
    font-size: 19.2px;
    line-height: 1.2;
    font-weight: 600;
  }
}
.contacts__form-description {
  font-size: 20px;
  line-height: 1.4;
  font-weight: 300;
  margin-bottom: 20px;
}
@media (max-width: 1256px) {
  .contacts__form-description {
    font-size: 18px;
    line-height: 1.4;
    font-weight: 300;
  }
}
@media (max-width: 768px) {
  .contacts__form-description {
    font-size: 15px;
    line-height: 1.4;
    font-weight: 300;
  }
}
@media (max-width: 320px) {
  .contacts__form-description {
    font-size: 12px;
    line-height: 1.4;
    font-weight: 300;
  }
}
.contacts .form {
  gap: 16px;
}
.contacts .form__accept-label {
  margin-top: 4px;
}
.contacts .form__label,
.contacts .form__input,
.contacts .form__button {
  width: 100%;
  max-width: initial;
}
.main-footer {
  padding: 40px 0;
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 1.3;
  font-weight: 400;
}
@media (max-width: 1256px) {
  .main-footer {
    font-size: 12.6px;
    line-height: 1.3;
    font-weight: 400;
  }
}
@media (max-width: 768px) {
  .main-footer {
    font-size: 10.5px;
    line-height: 1.3;
    font-weight: 400;
  }
}
@media (max-width: 320px) {
  .main-footer {
    font-size: 8.4px;
    line-height: 1.3;
    font-weight: 400;
  }
}
@media (max-width: 880px) {
  .main-footer {
    flex-direction: column;
    gap: 20px;
  }
}
.main-footer__logo-wrapper {
  display: flex;
  justify-content: space-between;
  max-width: 420px;
  gap: 32px;
  align-items: center;
}
@media (max-width: 880px) {
  .main-footer__logo-wrapper {
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
}
.main-footer__description {
  font-size: 13px;
  line-height: 1.4;
}
@media (max-width: 880px) {
  .main-footer__description {
    text-align: center;
  }
}
.main-footer__company {
  text-align: center;
  min-width: 200px;
}
.main-footer__document {
  text-align: center;
  min-width: 240px;
  color: inherit;
}
.main-footer .main-footer__social-links-wrapper {
  min-width: 68px;
  flex-wrap: nowrap;
}
.main-footer .main-footer__social-links-wrapper .contacts__social-link {
  color: #17181D;
}
.main-footer__address {
  text-align: center;
  min-width: 168px;
  max-width: 168px;
}
.main-footer__phone {
  text-align: center;
  min-width: 176px;
}
.main-footer .contacts__phone-link {
  font-size: 20px;
  line-height: 1;
  font-weight: 700;
  margin-bottom: 8px;
}
@media (max-width: 1256px) {
  .main-footer .contacts__phone-link {
    font-size: 18px;
    line-height: 1;
    font-weight: 700;
  }
}
@media (max-width: 768px) {
  .main-footer .contacts__phone-link {
    font-size: 15px;
    line-height: 1;
    font-weight: 700;
  }
}
@media (max-width: 320px) {
  .main-footer .contacts__phone-link {
    font-size: 12px;
    line-height: 1;
    font-weight: 700;
  }
}
.main-footer .contacts__when {
  font-size: 13px;
  line-height: 1;
  font-weight: 400;
  padding: 0;
}
@media (max-width: 1256px) {
  .main-footer .contacts__when {
    font-size: 11.7px;
    line-height: 1;
    font-weight: 400;
  }
}
@media (max-width: 768px) {
  .main-footer .contacts__when {
    font-size: 9.75px;
    line-height: 1;
    font-weight: 400;
  }
}
@media (max-width: 320px) {
  .main-footer .contacts__when {
    font-size: 7.8px;
    line-height: 1;
    font-weight: 400;
  }
}
.main-footer .contacts__when::before {
  display: none;
}
.main-header {
  position: absolute;
  z-index: 1;
  width: 100%;
  padding: 24px 0;
}
.main-header__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.main-header__logo-wrapper {
  display: flex;
  justify-content: space-between;
  max-width: 448px;
  gap: 28px;
  align-items: center;
}
@media (max-width: 768px) {
  .main-header__logo-image {
    width: 100px;
    height: 40px;
  }
}
.main-header__description {
  font-size: 18px;
  line-height: 1.4;
  color: #cdcdcd;
}
@media (max-width: 1100px) {
  .main-header__description {
    display: none;
  }
}
.main-header__contacts-wrapper {
  display: flex;
  gap: 48px;
  margin-left: 40px;
  align-items: center;
}
@media (max-width: 1100px) {
  .main-header__contacts-wrapper {
    gap: 24px;
    margin-left: initial;
  }
}
@media (max-width: 400px) {
  .main-header__contacts-wrapper {
    flex-direction: column;
    gap: 12px;
    align-items: flex-end;
  }
}
.main-header__messengers-wrapper {
  display: flex;
  align-items: center;
}
.main-header__messengers-description {
  font-size: 16px;
  line-height: 1.4;
  font-weight: 300;
  text-align: right;
  min-width: 128px;
}
@media (max-width: 1256px) {
  .main-header__messengers-description {
    font-size: 14.4px;
    line-height: 1.4;
    font-weight: 300;
  }
}
@media (max-width: 768px) {
  .main-header__messengers-description {
    font-size: 12px;
    line-height: 1.4;
    font-weight: 300;
  }
}
@media (max-width: 320px) {
  .main-header__messengers-description {
    font-size: 9.6px;
    line-height: 1.4;
    font-weight: 300;
  }
}
@media (max-width: 768px) {
  .main-header__messengers-description {
    min-width: 100px;
  }
}
@media (max-width: 580px) {
  .main-header__messengers-description {
    display: none;
  }
}
.main-header__messengers-link {
  padding: 0;
  min-width: 48px;
  min-height: 48px;
  max-width: 48px;
  max-height: 48px;
  border-radius: 50%;
  background-color: #05a0eb;
  background-image: url("../img/icons/stack.svg#telegram");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 21px 17px;
  margin-left: 20px;
}
@media (max-width: 768px) {
  .main-header__messengers-link {
    min-width: 30px;
    min-height: 30px;
    max-width: 30px;
    max-height: 30px;
    background-size: 15px 11px;
    margin-left: 16px;
  }
}
.main-header__messengers-link--whatsapp {
  background-color: #12c868;
  background-image: url("../img/icons/stack.svg#whatsapp");
  background-size: 22px 22px;
  margin-left: 12px;
}
@media (max-width: 768px) {
  .main-header__messengers-link--whatsapp {
    background-size: 16px 16px;
  }
}
.main-header__phone {
  min-width: 208px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (max-width: 768px) {
  .main-header__phone {
    min-width: initial;
  }
}
.main-header .contacts__when {
  padding-left: 12px;
  font-size: 14px;
  line-height: 1;
  font-weight: 300;
  margin-bottom: 12px;
}
@media (max-width: 1256px) {
  .main-header .contacts__when {
    font-size: 12.6px;
    line-height: 1;
    font-weight: 300;
  }
}
@media (max-width: 768px) {
  .main-header .contacts__when {
    font-size: 10.5px;
    line-height: 1;
    font-weight: 300;
  }
}
@media (max-width: 320px) {
  .main-header .contacts__when {
    font-size: 8.4px;
    line-height: 1;
    font-weight: 300;
  }
}
.main-header .contacts__when::before {
  width: 4px;
  height: 4px;
  top: 5px;
}
@media (max-width: 580px) {
  .main-header .contacts__when {
    display: none;
  }
}
.main-header .contacts__phone-link {
  font-size: 24px;
  line-height: 1;
  font-weight: 700;
  margin-bottom: 0;
}
@media (max-width: 1256px) {
  .main-header .contacts__phone-link {
    font-size: 21.6px;
    line-height: 1;
    font-weight: 700;
  }
}
@media (max-width: 768px) {
  .main-header .contacts__phone-link {
    font-size: 18px;
    line-height: 1;
    font-weight: 700;
  }
}
@media (max-width: 320px) {
  .main-header .contacts__phone-link {
    font-size: 14.4px;
    line-height: 1;
    font-weight: 700;
  }
}
@media (max-width: 580px) {
  .main-header .contacts__phone-link {
    font-size: 12px;
  }
}
.main-header__button {
  font-size: 12px;
  line-height: 1;
  font-weight: 700;
  padding: 20px 32px;
}
@media (max-width: 1256px) {
  .main-header__button {
    font-size: 10.8px;
    line-height: 1;
    font-weight: 700;
  }
}
@media (max-width: 768px) {
  .main-header__button {
    font-size: 9px;
    line-height: 1;
    font-weight: 700;
  }
}
@media (max-width: 320px) {
  .main-header__button {
    font-size: 7.2px;
    line-height: 1;
    font-weight: 700;
  }
}
@media (max-width: 1100px) {
  .main-header__button {
    display: none;
  }
}
